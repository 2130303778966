.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 10000;
}

.notification .me-auto {
  margin-right: auto;
}

.toast-header .btn-close {
  background: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") 50%/1em auto no-repeat #0000;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: initial;
  color: #000;
  height: 1em;
  opacity: .5;
  padding: 0.25em;
  width: 1em;
}
