.math-editor {
  position: relative;
  padding: 15px 15px 10px;
  border: 1px solid #2c2e33;
  border-radius: 1px;
  width: 100%;
  min-height: 75%;
  height: 75%;
  font-size: 1em;
  line-height: 1.2;
}

.math-operation {
  margin-right: 5px;
  font-size: 20px;
}

.math-operation.hover {
  background-color: #2b8d13
}


.react-contextmenu-wrapper {
  display: inline
}

.math-operation.input {
  padding: 3px;
}

.add-button {
  display: inline;
}

.add-button button {
  height: 35px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.add-button button i::before, .add-button button::after  {
  font-size: 20px;
  font-weight: 900;
}
