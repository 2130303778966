.product-main__additional {
  border: 1px solid #000;
  border-radius: 3px;
  padding: 1px 6px;
  font: 400 12px / 20px "Noto Sans", sans-serif;
  float: left;
  margin-right: 5px;
}

.product-main__additional--new {
  border-color: #7cbf1d;
}

.product-main__additional--popular {
  border-color: #ff9819;
}
